<template>
  <div class="box-not-found">
    <div class="img">
      <img src="../assets/not-found.png">
    </div>
    <div class="msg">
      <p>
        ຂໍອະໄພ, ພວກເຮົາບໍ່ສາມາດຄົ້ນຫາໜ້າທີ່ທ່ານຕ້ອງການ ກະລຸນາກົດປຸ່ມດ້ານລຸ່ມນິ້
      </p>
      <button v-if="!isActorAsMobileApp" class="button" :class="{'is-loading': btnLoading}" @click="LogoutUsers()">
        ອອກຈາກລະບົບ
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';


export default {
  data: () => ({
    btnLoading: false,
  }),
  computed: {
    isActorAsMobileApp() {
      return this.$route.query.actor == 'mobile_app';
    }
  },
  methods: {
    ...mapActions({
      LogoutUser: 'User/destroyToken'
    }),

    LogoutUsers() {
      this.btnLoading = true;
      this.LogoutUser().then(res => {
        if (res.data.success == true) {
          setTimeout(() => {
            this.btnLoading = false;
            this.$notification.OpenNotification_Logout_Success('top-right', 'danger', 3000);
          }, 500);
        }
      }).catch(() => {
        this.btnLoading = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.box-not-found {
  //background: $crimson;
  background-image: url('../assets/background.jpg');
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    margin-top: 50px;
    width: 300px;
  }

  .msg {
    font-size: 23px;
    color: $white;
    margin-top: -30px;

    .button {
      margin-top: 30px;
      background: $white;
      color: $crimson;
      padding: 20px;
      width: 200px;
      font-size: 20px;
    }
  }
}
</style>
